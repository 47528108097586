const news_categories = [
    "India",
    "Business",
    "Politics",
    "Sports",
    "Technology",
    "Entertainment",
    "International",
    "Automobile",
    "Science",
    "Travel",
    "Miscellaneous",
    "Fashion",
    "Education",
    "Health & Fitness"
]

export { news_categories }