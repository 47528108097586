// Footer.js
import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {
    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

    const toggleTermsModal = () => setIsTermsOpen(!isTermsOpen);
    const togglePrivacyModal = () => setIsPrivacyOpen(!isPrivacyOpen);

    const closeModal = () => {
        setIsTermsOpen(false);
        setIsPrivacyOpen(false);
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-column copyright">
                    <p>© 2024 <strong>BlipNews.</strong><br /> All rights reserved.</p>
                </div>
                <div className="footer-column links">
                    <button onClick={toggleTermsModal}>Terms & Conditions</button>
                    <span>|</span>
                    <button onClick={togglePrivacyModal}>Privacy Policy</button>
                </div>
                <div className="footer-column social-icons">
                    <a href="https://www.linkedin.com/company/blipnews" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/linkedin.png" alt="LinkedIn" />
                    </a>
                    <a href="https://x.com/BlipNews_app" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/twitter.png" alt="Twitter" />
                    </a>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        {/* <img src="src/icons/facebook.png" alt="Facebook" /> */}
                        <img src="/icons/facebook.png" alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com/blipnews_app" target="_blank" rel="noopener noreferrer">
                        <img src="/icons/instagram.png" alt="Instagram" />
                    </a>
                </div>
            </div>

            {(isTermsOpen || isPrivacyOpen) && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close" onClick={closeModal}>&times;</span>
                        {isTermsOpen && (
                            <>
                                <h2>Terms & Conditions</h2>
                                <p>
                                    Last updated: 1 July 2024<br />
                                    Welcome to BlipNews! By accessing or using our website, you agree to be bound by these Terms and Conditions ("Terms"). Please read them carefully.
                                    <ol className='modal-points'>
                                        <li><strong>Acceptance of Terms:</strong> By accessing or using the BlipNews website, you accept and agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website.</li>
                                        <li><strong>Changes to Terms:</strong> BlipNews reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the website after any changes signifies your acceptance of the updated Terms.</li>
                                        <li><strong>Use of the Website:</strong> You agree to use the website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website.</li>
                                        <li><strong>Content:</strong> All content provided on the BlipNews website is for informational purposes only. We make no representations as to the accuracy or completeness of any information on this site or found by following any link on this site.</li>
                                        <li><strong>Intellectual Property:</strong> All content on the BlipNews website, including text, graphics, logos, and images, is the property of BlipNews or its content suppliers and is protected by intellectual property laws.</li>
                                        <li><strong>Limitation of Liability:</strong> BlipNews will not be liable for any errors or omissions in this information nor for the availability of this information. BlipNews will not be liable for any losses, injuries, or damages from the display or use of this information.</li>
                                        <li><strong>Termination:</strong> We reserve the right to terminate or suspend your access to our website without prior notice or liability for any reason, including violation of these Terms.</li>
                                        <li><strong>Governing Law:</strong> These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.</li>
                                    </ol>
                                    <strong>Contact Us:</strong> If you have any questions about these Terms, please contact us at <a href="mailto:rajwada2001@gmail.com">rajwada2001@gmail.com</a>.
                                </p>

                            </>
                        )}
                        {isPrivacyOpen && (
                            <>
                                <h2>Privacy Policy</h2>
                                <p>
                                    Last updated: 1 July 2024<br />
                                    BlipNews ("we," "us," "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
                                    <ol className='modal-points'>
                                        <li><strong>Information We Collect</strong><br />
                                            Personal Information: We may collect personal information such as your name, email address, and other contact details when you sign up for our services.<br />
                                            Usage Data: We may collect information about your interactions with our website, such as the pages you visit, the time and date of your visit, and other diagnostic data.</li>
                                        <li><strong>How We Use Your Information</strong><br />
                                            We may use the information we collect in the following ways:<br />
                                            To provide and maintain our services<br />
                                            To notify you about changes to our services<br />
                                            To provide customer support<br />
                                            To monitor the usage of our website<br />
                                            To detect, prevent, and address technical issues</li>
                                        <li><strong>Sharing Your Information</strong><br />
                                            We do not sell, trade, or otherwise transfer your personal information to outside parties, except when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</li>
                                        <li><strong>Data Security</strong><br />
                                            We implement a variety of security measures to maintain the safety of your personal information. However, please be aware that no method of transmission over the internet, or method of electronic storage, is 100% secure.</li>
                                        <li><strong>Third-Party Services</strong><br />
                                            We may use third-party service providers to monitor and analyze the use of our website. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</li>
                                        <li><strong>Links to Other Websites</strong><br />
                                            Our website may contain links to other websites that are not operated by us. We strongly advise you to review...</li>
                                    </ol>
                                </p>

                            </>
                        )}
                    </div>
                </div>
            )}
        </footer>
    );
};

export default Footer;
