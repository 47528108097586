import React from "react";
import Pagination from "./Pagination";
import Stories from "./Stories";
import "./App.css";
import { useGlobalContext } from "./context";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";

const App = () => {
  const { isLoading } = useGlobalContext();
  return (
    <>
      <Navbar />
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div>
          <Stories />
          <Pagination />
        </div>
      )}
      <Footer />
    </>
  );
};

export default App;
