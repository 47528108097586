
import React, { useReducer, useEffect } from "react";

import reducer from "./reducer";

const AppContext = React.createContext();


// let news_url = "http://0.0.0.0:8000/news/?"
let news_url = "https://blipnews-api.koyeb.app/news/?"
const initialState = {
    isLoading: true,
    selected_category: '',
    no_of_pages: 0,
    page: 1,
    limit: 10,
    news: [],
}

const AppProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    const fetchApiData = async (url) => {
        dispatch({ type: "SET_LOADING" })
        try {
            console.log("fetch url: ", url)
            const response = await fetch(url);
            const data = await response.json();
            console.log("fetchApiData: ", data);
            dispatch({
                type: "SET_NEWS",
                payload: {
                    news: data.news,
                    total_no_records: data.total_no_records
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    // Pagination
    const getPrevPage = () => {
        dispatch({
            type: "PREV_PAGE"
        });
    }

    const getNextPage = () => {
        dispatch({
            type: "NEXT_PAGE"
        });
    }

    const getCategoryNews = (selected_category) => {
        dispatch({
            type: "SET_CATEGORY",
            payload: selected_category
        });
    }


    useEffect(() => {
        const offset = (state.page - 1) * state.limit;
        let url = `${news_url}category=${state.selected_category}&limit=${state.limit}&offset=${offset}`;
        fetchApiData(url);
    }, [state.page, state.selected_category, state.limit]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [state.page]);

    return (
        <AppContext.Provider value={{ ...state, getNextPage, getPrevPage, getCategoryNews }}>
            {children}
        </AppContext.Provider>
    )
}


const useGlobalContext = () => {
    return React.useContext(AppContext);
}

export { AppContext, AppProvider, useGlobalContext };