import React from "react";
import { useGlobalContext } from "./context";
const Pagination = () => {

    const {page, no_of_pages, getPrevPage, getNextPage} = useGlobalContext();

    return (
        <div className="pagination-btn">
            <button onClick={() => getPrevPage()}>Prev</button>
            <p>{page} of {no_of_pages}</p>
            <button onClick={() => getNextPage()}>Next</button>
        </div>
    );
}

export default Pagination;