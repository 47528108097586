const reducer = (state, action) => {

    switch (action.type) {
        case "SET_NEWS":
            return {
                ...state,
                news: action.payload.news,
                // news: [...state.news, ...action.payload.news],
                total_no_records: action.payload.total_no_records,
                no_of_pages: Math.ceil(action.payload.total_no_records / state.limit),
                isLoading: false
            }
        case "SET_LOADING":
            return {
                ...state,
                isLoading: true
            }
        case "PREV_PAGE":
            return {
                ...state,
                page: state.page === 1 ? state.page : state.page - 1
            }
        case "NEXT_PAGE":
            return {
                ...state,
                page: state.page === state.no_of_pages ? state.page : state.page + 1
            }
        case "SET_CATEGORY":
            return {
                ...state,
                selected_category: action.payload,
                page: 1
            }
        default:
            return state
    }
}

export default reducer;