
import React from "react";
import "./Navbar.css";
import { news_categories } from "../utils/constants";
import { useGlobalContext } from "../context"

const Navbar = () => {
  const { getCategoryNews } = useGlobalContext();

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <a href="/" onClick={refreshPage}>
          <img src="/assets/LOGO_WBG.png" alt="Logo" className="logo-img" />
        </a>
        <a href="/" onClick={refreshPage} className="logo-text">
          BlipNews
        </a>
      </div>
      <ul className="nav-menu">
        <li className="nav-item dropdown">
          <a href="#">Categories</a>
          <div className="dropdown-content">
            {news_categories.map((category, index) => (
              <a
                href="#"
                key={index}
                onClick={() => getCategoryNews(category)}
              >
                {category}
              </a>
            ))}
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
