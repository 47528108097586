import React from "react";
import { useGlobalContext } from "./context";

const Stories = () => {
    const { news, isLoading, selected_category } = useGlobalContext();
    console.log("Stories news: ", news);

    return (
        <>
            <div className="stories-div">
                {isLoading ? (
                    <h1>Loading...</h1>
                ) : news.length === 0 ? (
                    <h1>No news found for {selected_category}</h1>
                ) : (
                    news.map((item, index) => {
                        const {
                            url,
                            title,
                            summary,
                            category,
                            source,
                            published_at,
                            image_url,
                        } = item;
                        // Convert the published_at date to a readable format
                        const date = new Date(published_at);
                        const options = {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        };
                        const formattedDate = date.toLocaleString(
                            "en-US",
                            options
                        );

                        // Inline styles for the first card
                        const cardStyle =
                            index === 0 ? { marginTop: "100px" } : {};

                        return (
                            <div className="card" key={index} style={cardStyle} >
                                <div className="image-container">
                                    <img src={image_url} alt={title} />
                                </div>
                                <div className="content-container">
                                    <h2>{title}</h2>
                                    <p>
                                        {summary}{" "}
                                        <a href={url} target="_blank" rel="noreferrer"> Read More </a>
                                    </p>
                                    <p className="sub-card">
                                        <span>
                                            <b>Category</b>:{" "}
                                            {category.toString()}{" "}
                                        </span>
                                        <br />
                                        <span>
                                            <b>Reported by</b>: {source} |{" "}
                                            <b>Updated</b>: {formattedDate}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </>
    );
};

export default Stories;
